import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useEffect, useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import PortfolioEdit from '@widgets/portfolio/PortfolioEdit';
import { ReactComponent as GridViewIcon } from '@icons/wolfkit-light/square-4-grid-light.svg';
import { ReactComponent as ListViewIcon } from '@icons/wolfkit-light/list-bullet-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import ProfilePortfolioCard from '@entities/portfolio/ui/ProfilePortfolioCard';
import { IsDefined } from '@utils/js-ts';
import { ContainerColumn, ContainerRow } from '../../styled';
import { TitleSecondary } from '../../styled/Typography';
import Pill from './components/Pill';
import CarouselControls from './components/CarouselControls';
var CarouselViewType;
(function (CarouselViewType) {
    CarouselViewType["Card"] = "card";
    CarouselViewType["List"] = "list";
})(CarouselViewType || (CarouselViewType = {}));
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.m,
}));
const Headline = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ViewSwitchButtons = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs,
}));
const PortfoliosContainer = styled(ContainerRow)(() => ({
    height: 'auto',
}));
const CarouselChunkContainer = styled(ContainerRow, {
    shouldForwardProp: propName => propName !== 'fullChunk' && propName !== 'viewType',
})((props) => ({
    height: 'auto',
    flexDirection: props.viewType === 'list' ? 'column' : 'row',
    gap: props.viewType === 'list' ? '12px' : '18px',
}));
const PortfoliosPerChunk = 3;
const GetPortfolioCardViewType = (carouselViewType) => {
    let cardViewType = 'card';
    if (carouselViewType === CarouselViewType.Card) {
        cardViewType = 'card';
    }
    else if (carouselViewType === CarouselViewType.List) {
        cardViewType = 'list';
    }
    return cardViewType;
};
const PortfolioView = ({ portfolio, portfolioIdToEdit = undefined, selected, viewType, onClick, onAction, onEditClose, }) => {
    const handleOnClick = () => {
        onClick(portfolio.id);
    };
    return (_jsxs(_Fragment, { children: [(!IsDefined(portfolioIdToEdit) ||
                (viewType === 'list' &&
                    portfolioIdToEdit !== portfolio.id)) &&
                (_jsx(ProfilePortfolioCard, { portfolio: portfolio, viewType: GetPortfolioCardViewType(viewType), selected: selected, onClick: handleOnClick, onAction: onAction })), viewType === 'list' &&
                IsDefined(portfolioIdToEdit) &&
                portfolioIdToEdit === portfolio.id && (_jsx(PortfolioEdit, { portfolio: portfolio, onEditClose: onEditClose }))] }));
};
const PortfoliosCarousel = ({ portfolios: originalPortfoliosProp, onSelectPortfolioId, selectedPortfolioId = undefined, }) => {
    const { t } = useTranslation();
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const [portfolioToEdit, setPortfolioToEdit] = useState(undefined);
    const [currentView, setCurrentView] = useState(CarouselViewType.Card);
    const portfolios = originalPortfoliosProp.map((portfolio) => (Object.assign(Object.assign({}, portfolio), { 
        // TODO: remove, done to enable edit tests
        profile_id: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) || portfolio.profile_id, owner_profile_id: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) || portfolio.owner_profile_id })));
    const carouselChunks = useMemo(() => {
        const chunks = [];
        let chunk = [];
        portfolios.forEach((portfolio, idx) => {
            if (idx > 0 && idx % PortfoliosPerChunk === 0) {
                chunks.push(chunk);
                chunk = [];
            }
            chunk.push(Object.assign({}, portfolio));
        });
        if (chunk.length) {
            chunks.push(chunk);
        }
        return chunks;
    }, [portfolios]);
    const selectedPortfolioCarouselChunkIndex = useMemo(() => {
        if (selectedPortfolioId && carouselChunks.length) {
            for (let chunkIndex = 0; chunkIndex < carouselChunks.length; chunkIndex += 1) {
                const chunk = carouselChunks[chunkIndex];
                for (let i = 0; i < chunk.length; i += 1) {
                    const portfolio = chunk[i];
                    if (portfolio.id === selectedPortfolioId) {
                        return chunkIndex;
                    }
                }
            }
        }
        return 0;
    }, [
        selectedPortfolioId,
        carouselChunks,
    ]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(selectedPortfolioCarouselChunkIndex);
    const onNavigateLeft = () => {
        const newIndex = currentChunkIndex - 1;
        if (carouselChunks[newIndex]) {
            setCurrentChunkIndex(newIndex);
        }
    };
    const onNavigateRight = () => {
        const newIndex = currentChunkIndex + 1;
        if (carouselChunks[newIndex]) {
            setCurrentChunkIndex(newIndex);
        }
    };
    const onNavigateToChunk = (idx) => {
        if (idx !== currentChunkIndex && !!carouselChunks[idx]) {
            setCurrentChunkIndex(idx);
        }
    };
    const selectPortfolio = (id) => {
        onSelectPortfolioId(id);
    };
    const onPortfolioAction = (event, actionType, portfolioId) => {
        if (actionType === 'edit') {
            setPortfolioToEdit(portfolios.find(p => p.id === portfolioId));
        }
    };
    const onEditClose = () => {
        setPortfolioToEdit(undefined);
    };
    const currentChunk = useMemo(() => {
        let chunk = [];
        if (currentView === 'card' && carouselChunks[currentChunkIndex]) {
            chunk = carouselChunks[currentChunkIndex];
        }
        else if (currentView === 'list') {
            chunk = portfolios;
        }
        return chunk;
    }, [currentView, currentChunkIndex, carouselChunks, portfolios]);
    // NOTE: on selectedPortfolioId change we are setting carousel page to
    // the chunk where the selected portfolio is located
    useEffect(() => {
        setCurrentChunkIndex(selectedPortfolioCarouselChunkIndex);
    }, [selectedPortfolioCarouselChunkIndex]);
    return (_jsxs(Container, { children: [_jsxs(Headline, { children: [_jsx(TitleSecondary, { children: `${t('profile.tabs.portfolios')} (${portfolios.length})` }), _jsxs(ViewSwitchButtons, { children: [_jsx(Pill, { IconComponent: GridViewIcon, selected: currentView === CarouselViewType.Card, onClick: () => setCurrentView(CarouselViewType.Card) }), _jsx(Pill, { IconComponent: ListViewIcon, selected: currentView === CarouselViewType.List, onClick: () => setCurrentView(CarouselViewType.List) })] })] }), _jsx(PortfoliosContainer, { children: _jsxs(CarouselChunkContainer, { viewType: currentView, fullChunk: currentChunk.length === PortfoliosPerChunk, children: [(!IsDefined(portfolioToEdit) ||
                            currentView === 'list') &&
                            currentChunk.map((portfolio, idx) => (_jsx(PortfolioView, { viewType: currentView, portfolio: portfolio, portfolioIdToEdit: portfolioToEdit === null || portfolioToEdit === void 0 ? void 0 : portfolioToEdit.id, selected: portfolio.id === selectedPortfolioId, onClick: selectPortfolio, onAction: onPortfolioAction, onEditClose: onEditClose }, idx))), currentView === 'card' &&
                            IsDefined(portfolioToEdit) && (_jsx(PortfolioEdit, { portfolio: portfolioToEdit, onEditClose: onEditClose }))] }) }), currentView === 'card' &&
                (_jsx(CarouselControls, { pagesLength: carouselChunks.length, currentPageIndex: currentChunkIndex, disabled: IsDefined(portfolioToEdit), onNavigateLeft: onNavigateLeft, onNavigateRight: onNavigateRight, onNavigateToPageIndex: onNavigateToChunk }))] }));
};
export default PortfoliosCarousel;
